import React, { useState, useEffect } from 'react';

// BlogCard component to accept props
const BlogCard = ({ blog }) => {
  return (
    <div className="bg-white shadow-lg rounded-lg flex flex-col md:flex-row p-6 md:max-w-xl">
      <div className="md:flex-shrink-0">
        <img
          className="w-full md:w-56 h-32 md:h-full rounded-lg object-cover"
          src={`https://website-backend-ocng.onrender.com/api/v1/assets?fileName=${blog.pictureUrl}`}
          alt={blog.pictureAlt || "Blog"}
        />
      </div>
      <div className="mt-4 md:mt-0 md:ml-6 flex flex-col justify-between">
        <div>
          <div className="uppercase tracking-wide text-sm text-gray-600 font-bold">
            {new Date(blog.createdAt).toLocaleDateString()} {/* Example date formatting */}
          </div>
          <a href="#" className="block mt-1 text-lg leading-tight font-semibold text-gray-900 hover:underline">
            {blog.heading}
          </a>
          <p className="mt-2 text-gray-600">
            {blog.subheading}
          </p>
        </div>
        <a  href={`/News-&-Insights/read-more/${blog._id}/${encodeURIComponent(blog.heading)}`} className="text-blue-500 hover:text-blue-800 mt-2">
          Read more
        </a>
      </div>
    </div>
  );
};

const BlogSection = () => {
  const [latestBlog, setLatestBlog] = useState(null);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch('https://website-backend-ocng.onrender.com/api/v1/content?type=blog&isTrending=true');
        const result = await response.json();
        // Get the latest blog (first item in the array)
        setLatestBlog(result.data[0]);
      } catch (error) {
        console.error('Error fetching blog data:', error);
      }
    };

    fetchBlogs();
  }, []);

  return (
    <div style={{fontFamily: 'Roboto'}}>
      <div className="bg-white text-black p-12 flex justify-center items-center">
        <div className="flex flex-col md:flex-row items-center max-w-6xl mx-auto">
          <div className="md:w-1/2 text-left md:pr-6">
            <h1 className="text-4xl font-bold">Check out our Blog!</h1>
            <p className="text-black mt-2">
              Explore our latest blog posts where we delve into the trends, strategies, and innovations driving digital transformation across industries. Stay informed and inspired with valuable insights to navigate the evolving digital landscape effectively.
            </p>
            <div className="mt-6">
              <button className="bg-green-500 text-white font-bold py-2 px-4 rounded hover:bg-green-600">
                Subscribe
              </button>
            </div>
          </div>
          <div className="md:w-1/2 mt-8 md:mt-0">
            {latestBlog ? (
              <BlogCard blog={latestBlog} />
            ) : (
              <p>Loading...</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogSection;
