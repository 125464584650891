import React, { useState } from 'react';
import axios from 'axios';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    service: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      fullName: formData.name,
      email: formData.email,
      message: formData.message,
      optionals: {
        phoneNumber: formData.phone,
        industry: formData.service
      }
    };

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://website-backend-ocng.onrender.com/api/v1/activity/formSubmit', // Replace with actual API URL
      headers: { 'Content-Type': 'application/json' },
      data: JSON.stringify(data)
    };

    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        // Optionally, reset the form here
        setFormData({
          name: '',
          email: '',
          phone: '',
          service: '',
          message: ''
        });
      })
      .catch((error) => {
        console.error('Error submitting form:', error);
      });
  };

  return (
    <div className="max-w-4xl mx-auto p-6 py-12 mt-12">
      <h1 className="text-4xl font-semibold text-[#2B3E48] mb-6" style={{ fontSize: '52px', fontFamily: 'Roboto', wordWrap: 'break-word' }}>
        Get in touch with us
      </h1>
      <p className="text-lg text-[#11222C] mb-6" style={{ fontSize: '20px', fontFamily: 'Roboto', fontWeight: 400, wordWrap: 'break-word' }}>
        Get a quote today!
      </p>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
            <input
              type="text"
              name="name"
              id="name"
              value={formData.name}
              onChange={handleChange}
              required
              className="mt-1 block w-full border border-[#2B3E48] rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              name="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="mt-1 block w-full border border-[#2B3E48] rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label htmlFor="phone" className="block text-sm font-medium text-gray-700">Phone (optional)</label>
            <input
              type="tel"
              name="phone"
              id="phone"
              value={formData.phone}
              onChange={handleChange}
              className="mt-1 block w-full border border-[#2B3E48] rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          <div>
            <label htmlFor="service" className="block text-sm font-medium text-gray-700">Services you are interested in</label>
            <select
              name="service"
              id="service"
              value={formData.service}
              onChange={handleChange}
              className="mt-1 block w-full border border-[#2B3E48] rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            >
              <option value="">Select a service</option>
              <option value="service1">Service 1</option>
              <option value="service2">Service 2</option>
              <option value="service3">Service 3</option>
            </select>
          </div>
        </div>
        <div>
          <label htmlFor="message" className="block text-sm font-medium text-gray-700">Your message</label>
          <textarea
            name="message"
            id="message"
            value={formData.message}
            onChange={handleChange}
            className="mt-1 block w-full border border-[#2B3E48] rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            rows="4"
          />
        </div>
        <button
          type="submit"
          className="inline-flex items-center"
          style={{
            paddingLeft: '18px',
            paddingRight: '18px',
            paddingTop: '10px',
            paddingBottom: '10px',
            background: '#2B3E48',
            borderRadius: '45px',
            overflow: 'hidden',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '12px',
            display: 'inline-flex'
          }}
        >
          <div style={{ textAlign: 'center', color: 'white', fontSize: '14px', fontFamily: 'Roboto', fontWeight: 600, lineHeight: '20px', wordWrap: 'break-word' }}>
            Send Message
          </div>
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
