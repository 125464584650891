import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { NavBar } from './NavBar';
import CheckOut from './Newspage/CheckOut';
import Footer from './ui/Footer/Footer';
import axios from 'axios';
import FullPageLoader from '../components/ui/FullPageLoader/FullPageLoader';

const timestampToLocalDateConverter = (timestamp) => {
  const date = new Date(timestamp);
  const locale = navigator.language;
  const dateOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  const formattedDate = new Intl.DateTimeFormat(locale, dateOptions).format(date);
  return `${formattedDate}`;
};

const formatHeadingForUrl = (heading) => {
  return heading.replace(/ /g, '-').replace(/[\s:]/g, "");
};

const ReadMore = () => {
  const { id, heading } = useParams();
  const [content, setContent] = useState(null);

  const getContentById = async (id) => {
    try {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `https://website-backend-ocng.onrender.com/api/v1/content/${id}`,
        headers: {},
      };

      const response = await axios.request(config);
      setContent(response.data.data.content);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getContentById(id);
  }, [id]);

  useEffect(() => {
    if (content) {
      const canonicalUrl = `https://pemsdigital.com/News-&-Insights/read-more/${id}/${formatHeadingForUrl(content.heading)}`;
      const canonicalLinkElement = document.querySelector('link[rel="canonical"]');
      if (canonicalLinkElement) {
        canonicalLinkElement.setAttribute('href', canonicalUrl);
      } else {
        const newLinkElement = document.createElement('link');
        newLinkElement.setAttribute('rel', 'canonical');
        newLinkElement.setAttribute('href', canonicalUrl);
        document.head.appendChild(newLinkElement);
      }

      document.title = content.heading;
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', content.subheading);
      } else {
        const newMetaElement = document.createElement('meta');
        newMetaElement.setAttribute('name', 'description');
        newMetaElement.setAttribute('content', content.subheading);
        document.head.appendChild(newMetaElement);
      }
    }
  }, [content, id]);

  const renderParagraph = (para, index) => {
    return (
      <section key={index} className="mb-8">
        {para.heading && <h3 className="text-2xl font-bold mb-2">{para.heading}</h3>}
        {para.subheading && <h4 className="text-xl font-semibold mb-2">{para.subheading}</h4>}
        <p className="mb-4">{para.text}</p>
        {para.type === 'ordered' && (
          <ol className="list-decimal ml-5">
            {para.listItems.map((item, idx) => (
              <li key={idx} className="mb-2">
                {item.listItemType === 'link' ? (
                  <a href={item.listText} className="text-blue-600 underline">{item.listText}</a>
                ) : (
                  <>
                    {item.listHeading && <strong>{item.listHeading}</strong>}
                    {item.listText && <span>: {item.listText}</span>}
                  </>
                )}
              </li>
            ))}
          </ol>
        )}
        {para.type === 'unordered' && (
          <ul className="list-disc ml-5">
            {para.listItems.map((item, idx) => (
              <li key={idx} className="mb-2">
                {item.listItemType === 'link' ? (
                  <a href={item.listText} className="text-blue-600 underline">{item.listText}</a>
                ) : (
                  <>
                    {item.listHeading && <strong>{item.listHeading}</strong>}
                    {item.listText && <span>: {item.listText}</span>}
                  </>
                )}
              </li>
            ))}
          </ul>
        )}
      </section>
    );
  };

  return !content ? (
    <FullPageLoader />
  ) : (
    <div>
      <NavBar />
      <div className="max-w-3xl mt-12 mx-auto p-6">
        <header className="mb-8">
          <h1 className="text-4xl font-bold mb-2">{content.heading}</h1>
          <div className="text-gray-600 mb-4">
            Published on: {timestampToLocalDateConverter(content.createdAt)}
          </div>
          <div className="text-gray-600 mb-4">
            Author: {content.author.name}, {content.author.designation}
          </div>
        </header>
        <img
          src={`https://website-backend-ocng.onrender.com/api/v1/assets/?fileName=${content.pictureUrl}`}
          alt={content.pictureAlt}
          className="w-full h-auto mb-8"
        />
        {content.paragraphs.map((para, index) => renderParagraph(para, index))}
      </div>
      <CheckOut />
      <Footer />
    </div>
  );
};

export default ReadMore;
