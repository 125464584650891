import React, { useState, useEffect } from 'react';
import axios from 'axios';

const timestampToLocalDateConverter = (timestamp) => {
  const date = new Date(timestamp);
  const locale = navigator.language;
  const dateOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  const formattedDate = new Intl.DateTimeFormat(locale, dateOptions).format(
    date
  );
  return `${formattedDate}`;
};

const handleReadMoreClick = (id, heading) => {
  const formattedHeading =  heading.replace(/ /g, '-').replace(/[\s:]/g,"")
  window.location.href = `/News-&-Insights/read-more/${id}/${formattedHeading
  }`;
};

const ResourceCard = ({ heading, createdAt, subheading, _id, pictureUrl }) => (
  <div className="bg-gray-100 shadow-md  rounded-md overflow-hidden">
    <img
      src={`https://website-backend-ocng.onrender.com/api/v1/assets/?fileName=${pictureUrl}`}
      alt="Resource"
      className="w-full h-48 object-cover"
    />
    <div className="p-4">
      <h2 className="text-lg font-bold mb-2">{heading}</h2>
      <p className="text-gray-600 text-sm">{subheading}</p>
      <div className="mt-4 flex items-center justify-between">
        <span className="text-sm text-gray-500">
          {timestampToLocalDateConverter(createdAt)}
        </span>
        <button
          onClick={() => handleReadMoreClick(_id, heading)}
          className="text-blue-500 hover:underline"
        >
          Read More
        </button>
      </div>
    </div>
  </div>
);

const Resources = () => {
  const [resources, setResources] = useState([]);
  const [activeTab, setActiveTab] = useState('all');

  const getContent = async (type = '', isTrending = '') => {
    try {
      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `https://website-backend-ocng.onrender.com/api/v1/content?page=0&type=${type}&isTrending=${isTrending}`,
        headers: {},
      };
      const contentData = await axios.request(config);
      console.log(contentData.data.data);
      setResources(contentData.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleTabClick = async (tab, type = '', isTrending = '') => {
    setActiveTab(tab);
    await getContent(type, isTrending);
  };

  useEffect(() => {
    getContent();
  }, []);

  return (
    <div className="max-w-6xl mx-auto p-6">
      <h1 className="text-3xl font-bold mb-4">Resources</h1>
      <div className="flex space-x-4 mb-4">
        <button
          className={`px-4 py-2 rounded-full ${
            activeTab === 'all'
              ? 'bg-teal-500 text-white'
              : 'bg-gray-200 text-gray-700'
          }`}
          onClick={() => handleTabClick('all')}
        >
          All (5)
        </button>
        <button
          className={`px-4 py-2 rounded-full ${
            activeTab === 'trending'
              ? 'bg-teal-500 text-white'
              : 'bg-gray-200 text-gray-700'
          }`}
          onClick={() => handleTabClick('trending', '', true)}
        >
          Trending (5)
        </button>
        <button
          className={`px-4 py-2 rounded-full ${
            activeTab === 'blog'
              ? 'bg-teal-500 text-white'
              : 'bg-gray-200 text-gray-700'
          }`}
          onClick={() => handleTabClick('blog', 'blog')}
        >
          Blogs (4)
        </button>
        <button
          className={` px-4 py-2 rounded-full ${
            activeTab === 'update'
              ? 'bg-teal-500 text-white'
              : 'bg-gray-200 text-gray-700'
          }`}
          onClick={() => handleTabClick('update', 'update')}
        >
          Updates (1)
        </button>
        <button
          className={` disable px-4  py-2 rounded-full ${
            activeTab === 'events'
              ? 'bg-teal-500 text-white'
              : 'bg-gray-200 text-gray-700'
          }`}
          onClick={() => handleTabClick('events', 'events')}
        >
          Events (0)
        </button>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {resources.map((resource, index) => (
          <ResourceCard key={index} {...resource} />
        ))}
      </div>
    </div>
  );
};

export default Resources;
