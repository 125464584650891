import React from 'react';

const timestampToLocalDateConverter = (timestamp) => {
  const date = new Date(timestamp);
  const locale = navigator.language;
  const dateOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  const formattedDate = new Intl.DateTimeFormat(locale, dateOptions).format(date);
  return `${formattedDate}`;
};

const formatHeadingForUrl = (heading) => {
  return heading.replace(/ /g, '-').replace(/[\s:]/g,"")
};


const handleReadMoreClick = (heading,_id) => {
  const formattedHeading = formatHeadingForUrl(heading);
  console.log(formattedHeading);
  window.location.href = `/News-&-Insights/read-more/${_id}/${formattedHeading}`;
};

const ResourceCard = ({ _id, heading, createdAt, subheading, pictureUrl }) => {

  return (
    <div className="bg-gray-100 shadow-md rounded-md overflow-hidden mx-8 flex-shrink-0 w-1/3">
      <img
        src={`https://website-backend-ocng.onrender.com/api/v1/assets/?fileName=${pictureUrl}`}
        // src={`https://website-backend-ocng.onrender.com/api/v1/assets/?fileName=${content.pictureUrl}`}
        alt="Resource"
        className="w-full h-48 object-cover"
      />
      <div className="p-4">
        <h2 className="text-lg font-bold mb-2">{heading}</h2>
        <p className="text-gray-600 text-sm">{subheading}</p>
        <div className="mt-4 flex items-center justify-between">
          <span className="text-sm text-gray-500">
            {timestampToLocalDateConverter(createdAt)}
          </span>
          <button
            onClick={()=>handleReadMoreClick(heading,_id)}
            className="text-blue-500 hover:underline"
          >
            Read More
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResourceCard;
